<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"></label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="8" class="my-1">

        </b-col>
        <b-col md="4" class="my-1">
          <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(wbs)="data">
              <b-row>
                <b-col md="4">
                  <small class="text-primary">Nama SKPD</small>
                  <h6 class="text-primary"><strong>{{ data.item.kode_dinas }} {{ data.item.nama_dinas }}</strong></h6>
                </b-col>
                <b-col md="2">
                  <h6 style="text-align: center;">
                    <small>Pagu Anggaran</small><br>
                    <strong><sup>Rp. </sup>{{ Rp(data.item.pagu_anggaran) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6 style="text-align: center;">
                    <small>Nilai Kontrak</small><br>
                    <strong><sup>Rp. </sup>{{ Rp(data.item.nilaikontrak) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6 style="text-align: center;">
                    <small>Realisasi Kontrak</small><br>
                    <strong><sup>Rp. </sup>{{ Rp(data.item.realisasi) }}</strong>
                  </h6>
                </b-col>
                <b-col md="2">
                  <h6 style="text-align: center;">
                    <small>Prosen </small><br>
                    <strong> {{ data.item.prosen }} %</strong>
                  </h6>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'

export default {
  components: {
    BTable, BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  mixins: [Base],
  data() {
    return {
      perPage: 50,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    this.load_data();
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/rekap_belanja/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>